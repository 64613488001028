import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { hyphenated } from "hyphenated"

import Seo from "../components/seo"
import Localize from "../components/localize"
import BackButton from "../components/backButton"

import { ServiceSvg } from "../svgs/svg"

import { RightArrow } from "../shared/icons/icons"

const Service = ({
  data: { service, serviceDetail, site },
  pageContext,
  location,
}) => {
  const [filter, setFilter] = useState(service.service_type[0].title)
  const lan = pageContext.locale
  const filteredServiceDetails = []
  const [scrollPosition, setScrollPosition] = useState(0)
  const allServiceDetails = serviceDetail.edges
  const [serviceDetails, setServiceDetails] = useState(serviceDetail.edges)

  const onChangeFilter = filter => {
    setFilter(filter)
    allServiceDetails.forEach(detail => {
      if (
        detail.node.service_type.length > 0 &&
        detail.node.service_type[0].title === filter
      ) {
        filteredServiceDetails.push(detail)
      }
    })
    setServiceDetails(filteredServiceDetails)
  }

  useEffect(() => {
    if (location.state && location.state.filter) {
      onChangeFilter(location.state.filter)
    } else {
      onChangeFilter(filter)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  }, [location.state])

  useEffect(() => {
    if (location.state && location.state.scroll && !location.state.home) {
      window.scroll({
        top: location.state.scroll,
        behavior: "smooth",
      })
    }
  }, [location.state])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  return (
    <>
      <Seo
        lan={lan}
        title={`${lan === "en" ? "Services" : "Dienstleistungen"} - ${
          service.title
        }`}
        description={
          lan === "en"
            ? "Our team of lawyers consists of competent legal advisors who together cover a broad range of specialist areas. The same applies to our notaries, who are there for you in all matters requiring notarial or official certification."
            : "Unser Anwaltsteam besteht aus kompetenten Rechtsberatern, die zusammen ein breites Spektrum an Fachgebieten abdecken. Gleiches gilt für unsere Notare, die in allen Angelegenheiten, die eine notarielle oder behördliche Beglaubigung erfordern, für Sie da sind."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Services: Legal Advice & Notaries, Tax Consultancy & Auditing and Real Estate Consulting & Management Consultancy - Haackschubert"
            : "Dienstleistungen: Rechtsberatung & Notare, Steuerberatung & Wirtschaftsprüfung, Immobilien- & Unternehmensberatung - Haackschubert"
        }
      />
      <BackButton
        to={`/${lan}/`}
        state={location.state}
        lan={lan}
        text={lan === "en" ? "Home" : "Startseite"}
      />
      <div
        style={{ height: "auto" }}
        className="header no--margin-menu header-typ2 about--us-header"
      >
        <ServiceSvg />
        <div
          id="headerText"
          className="headerText auto-adjust-header about--us-text about-text"
        >
          <h1 className="m-0">{service.title}</h1>
          {service.description.map(des => {
            return des.children.map((de, index) => {
              return (
                <p key={index} style={{ whiteSpace: "pre-wrap" }}>
                  {de.text}
                </p>
              )
            })
          })}
          <Link
            to={`/${pageContext.locale}/contact/`}
            state={{ service: service.first_button_text }}
            className="button contact"
          >
            <div></div>
            <span className="icon-arrow_right">
              <RightArrow />
            </span>
            <b className="font-weight-300">{site.siteMetadata[lan].contact} </b>
            <b className="fs-15-res" style={{ marginRight: "-9px" }}>
              {service.first_button_text.toLowerCase()}
            </b>
          </Link>
          <Link
            to={`/${pageContext.locale}/contact/`}
            state={{ service: service.second_button_text }}
            className="button contact"
          >
            <div></div>
            <span className="icon-arrow_right">
              <RightArrow />
            </span>
            <b className="font-weight-300">{site.siteMetadata[lan].contact} </b>
            <b className="fs-15-res" style={{ marginRight: "-9px" }}>
              {service.second_button_text.toLowerCase()}
            </b>
          </Link>
        </div>
      </div>
      <div className="subpagemenu">
        <div className="subpagemenubuttons">
          {service.service_type.map((type, index) => {
            return (
              <div
                key={index}
                role="button"
                tabIndex={0}
                className={
                  filter === type.title
                    ? "subpagebutton selected"
                    : "subpagebutton"
                }
                onClick={() => onChangeFilter(type.title)}
                onKeyDown={() => onChangeFilter(type.title)}
                data-id="0"
              >
                <div>{type.title}</div>
              </div>
            )
          })}
        </div>
        <div>
          <div
            id="subpagesubs-0"
            className="d-block swiper-container swiper-container-0 subpagesubs"
            style={{ marginBottom: "70px" }}
          >
            <div className="swiper-wrapper home-page-swiper">
              {serviceDetails.map((detail, index) => (
                <Link
                  key={index}
                  state={{
                    route: `/${lan}/services/${detail.node.main_service_type[0].slug.current}/`,
                    filter: filter,
                    scroll: scrollPosition,
                  }}
                  to={`/${lan}/services/${detail.node.main_service_type[0].slug.current}/${detail.node.slug.current}/`}
                >
                  <div className="swiper-slide swiperr">
                    <h4 style={{ height: "90px" }}>
                      {hyphenated(detail.node.title)}
                    </h4>
                    <p className="service-detail-sd">
                      {hyphenated(detail.node.subDescription)}
                    </p>
                    <div className="link">
                      <span style={{ width: "64%" }} className="hs_arrow_right">
                        {site.siteMetadata[lan].goToDetails}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            ></span>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!, $fields: [SanityServiceFieldsEnum] = _id) {
    site {
      siteMetadata {
        siteUrl
        en {
          goToDetails
          contact
        }
        de {
          goToDetails
          contact
        }
      }
    }
    service: sanityMainService(slug: { current: { eq: $slug } }) {
      title {
        _type
        en
        de
      }
      service_type {
        title {
          _type
          en
          de
        }
      }
      description {
        _type
        en {
          children {
            text
          }
        }
        de {
          children {
            text
          }
        }
      }
      first_button_text {
        _type
        en
        de
      }
      second_button_text {
        _type
        en
        de
      }
    }
    serviceDetail: allSanityService(sort: { fields: $fields, order: ASC }) {
      edges {
        node {
          _rawTitle
          title {
            _type
            en
            de
          }
          main_service_type {
            slug {
              current
            }
          }
          service_type {
            title {
              _type
              en
              de
            }
          }
          subDescription {
            _type
            en
            de
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default Localize(Service)
